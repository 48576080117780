import React from "react";

import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import $ from "jquery";

import "./Pricelist.css";
import Navbar_en from "../../../components/Navbar_en/Navbar_en";

export default function Pricelist() {
  $(document).ready(function () {
    $(".opening-hours li").eq(new Date().getDay()).addClass("today");
  });

  return (
    <motion.div className="Pricelist-page" initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}>
      <Helmet>
        <title>Royal Class Barber Shop | Pricelist</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>

      <Navbar_en />
      <section className="Pricelist">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInRight">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>Classic cut</h1>
                    <h3>420&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=e76e14d3-926d-472c-aefc-a087dcf9c0d9">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInRight">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>
                      Classic cut +<br /> nose hair removal
                    </h1>
                    <h3>460&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=68e85f45-fc8f-448c-948c-90c1396322f4">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInLeft">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>Complete care</h1>
                    <h3>690&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=2e175578-1634-49d4-a5ea-a6a934b7cc0d">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInLeft">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>
                      Classic cut Father <br /> + son
                    </h1>
                    <h3>760&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=2efdd5d0-bb15-4b2d-9514-172435f2243c">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInRight">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>
                      Shaving with a razor
                      <br />
                      (hot towel)
                    </h1>
                    <h3>380&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=ebf23e77-7838-4c52-b4de-4c4c2269d774">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInRight">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>
                      Beard trimming + <br />
                      beard coloring
                    </h1>
                    <h3>620&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=b6e50db9-39fc-4935-b826-cdd82458e3ab">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInLeft">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>
                      Complete care + <br />
                      Beard coloring
                    </h1>
                    <h3>980&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=a2f91cda-8cf3-457b-a2bf-93e429452443">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="Pricelist-card animate__animated animate__slideInLeft">
                <div className="Pricelist-card-front">
                  <div className="Pricelist-front-content">
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                    <h1>
                      All inclusive
                      <br /> Royal Class
                    </h1>
                    <h3>1180&nbsp;Kč</h3>
                  </div>
                </div>
                <div className="Pricelist-card-back">
                  <div className="Pricelist-back-content">
                    <a href="https://royal-class.reservio.com/booking/business/service/resource?businessId=ebf7b343-96a9-4bdf-8c31-011177c1b05a&serviceId=66c0c4d0-75be-4333-bd95-fd4c95df6fa6">
                      <span class="btn-reservation">Reserve</span>
                    </a>
                    <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Opening-hours">
        <div class="container">
          <div className="center-wrap">
            <div class="business-hours Pricelist-card animate__animated animate__slideInUp">
              <h2 class="title">Opening hours</h2>
              <ul class="list-unstyled opening-hours">
                <li>
                  Sunday <span class="pull-right">- Closed</span>
                </li>
                <li>
                  Monday <span class="pull-right">9:00 - 19:00</span>
                </li>
                <li>
                  Tuesday <span class="pull-right">9:00 - 19:00</span>
                </li>
                <li>
                  Wednesday <span class="pull-right">9:00 - 19:00</span>
                </li>
                <li>
                  Thursday <span class="pull-right">9:00 - 19:00</span>
                </li>
                <li>
                  Friday <span class="pull-right">9:00 - 19:00</span>
                </li>
                <li>
                  Saturday <span class="pull-right">- Closed</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
