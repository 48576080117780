import React from "react";

import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import "../../../pages/cz/Employee/Employee.css";
import Navbar from "../../../components/Navbar/Navbar";

export default function Employee() {
  return (
    <motion.div className="employee-page" initial={{ width: "-100" }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}>
      <Helmet>
        <title>Royal Class Barber Shop | Lukáš Novelinka</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <Navbar />
      <section id="employee">
        <div className="employee-content">
          <div className="container">
            <h1 className="animate__animated animate__fadeIn">Lukáš Novelinka</h1>
            <h3>Hairdresser</h3>
            <div className="picture">
              <div className="imgteam animate__animated animate__pulse">
                <img draggable="false" src=".././images/lukas.jpg" alt="person" />
              </div>
            </div>
            <p className="animate__animated animate__fadeInUp">Jsem Lukáš, stříhání je moje práce a zároveň hobby. Chci aby se zákazník cítil spokojený po všech stránkách, ať už jde o střih nebo příjemnou atmosféru. Každým střihem se snažím zlepšovat své dovednosti. Pokud tedy chceš kvalitně ostříhat a zároveň zrelaxovat, neváhej a rezervuj se. </p>
            <div className="soc animate__animated animate__fadeInUp">
              <a href="https://www.instagram.com/royalclassbarber/?hl=cs">
                <i class="bx bxl-instagram"></i>
              </a>
            </div>
            <div className="toTeam">
              <Link as={Link} to={"/cz/team"}>
                <i class="bx bx-chevron-left"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
