import React from "react";

import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import "../../../pages/cz/Employee/Employee.css";

export default function Employee() {
  return (
    <motion.div className="employee-page" initial={{ width: "-100" }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}>
      <Helmet>
        <title>Royal Class Barber Shop | Employee</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <section id="employee">
        <div className="employee-content">
          <div className="container">
            <h1>JMÉNO-PŘÍJMENÍ</h1>
            <h3>Hairdresser</h3>
            <div className="picture">
              <div className="imgteam">
                <img draggable="false" src="./images/person2.png" alt="person" />
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, beatae dolorem. Et assumenda, aspernatur sunt libero provident doloribus quod asperiores distinctio omnis. Velit animi eveniet consequatur soluta inventore temporibus dolorem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad consequuntur numquam, adipisci ea voluptatum cumque non neque deserunt minima fugiat nulla reiciendis, alias voluptatibus, ab sequi quos. Tenetur, autem nam.</p>
            <div className="soc">
              <i class="bx bxl-instagram"></i>
            </div>
            <div className="toTeam">
              <Link as={Link} to={"/cz/team"}>
                <i class="bx bx-chevron-left"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
