import React from "react";

import $ from "jquery";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "../../../src/pages/cz/Home/Home.css";

export default function Navbar() {
  $("document").ready(function () {
    $(".menu-icon-hamburger").on("click", function () {
      $(".menu-icon-hamburger").hide(1);
      $(".menu-icon-x").show(1);
      $("#myDIV").show(1);
    });

    $(".menu-icon-x").on("click", function () {
      $(".menu-icon-hamburger").show(1);
      $(".menu-icon-x").hide(1);
      $("#myDIV").hide(1);
    });
  });

  const mediaQuery = window.matchMedia("(max-width: 1190px)");

  if (mediaQuery.matches) {
    $("document").ready(function () {
      $(".btn").on("click", function () {
        $("#myDIV").hide(1);
        $(".menu-icon-x").hide(1);
        $(".menu-icon-hamburger").show(1);
      });
    });
  }

  document.documentElement.style.setProperty("--animate-duration", "1200ms");

  return (
    <nav>
      <div className="menu">
        <Link as={Link} to={"/en"}>
          <img class="logo" src=".././images/barberlogo.svg" alt="logo" draggable="false" />
        </Link>
        <ul id="myDIV">
          <Link as={Link} to={"/en"}>
            <li>
              <a className="btn mobile-btn">Home</a>
            </li>
          </Link>

          <Link as={Link} to={"/en/team"} offset={-100}>
            <li>
              <a className="btn mobile-btn">Team</a>
            </li>
          </Link>

          <Link to={"/en/pricelist"}>
            <li>
              <a className="btn mobile-btn">Pricelist / Opening hours</a>
            </li>
          </Link>

          <Link as={Link} to={"/en/gallery"}>
            <li>
              <a className="btn mobile-btn">Gallery</a>
            </li>
          </Link>

          <Link to="/en/contact">
            <li>
              <a className="btn mobile-btn">Contact</a>
            </li>
          </Link>

          <li className="animate__animated animate__bounceInRight">
            <a className="btnrezervation" href="https://royal-class.reservio.com/">
              Reserve
            </a>
          </li>

          <div className="dropdown mobile-dropdown" style={{ display: "none" }}>
            <Link as={Link} to={"/cz"}>
              <a className="czech_a">
                <img className="czech_img" src=".././images/czech-republic.svg" />
                Czech
              </a>
            </Link>
            <Link as={Link} to={"/en"}>
              <a className="en_a">
                <img className="en_img" src=".././images/united-kingdom.svg" />
                English
              </a>
            </Link>
          </div>
        </ul>

        <div className="dropdown">
          <Link as={Link} to={"/cz"}>
            <a className="czech_a">
              <img className="czech_img" src=".././images/czech-republic.svg" draggable="false" />
              Czech
            </a>
          </Link>
          <hr />
          <Link as={Link} to={"/en"}>
            <a className="en_a">
              <img className="en_img" src=".././images/united-kingdom.svg" draggable="false" />
              English
            </a>
          </Link>
        </div>

        <div className="mobile-navbar" style={{ display: "none" }}>
          <a className="menu-button">
            <div className="menu-icon-hamburger">
              <i class="bx bx-menu"></i>
            </div>
            <div className="menu-icon-x" style={{ display: "none" }}>
              <i class="bx bx-x"></i>
            </div>
          </a>
        </div>
      </div>
    </nav>
  );
}
