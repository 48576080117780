import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import $ from "jquery";

import Home from "../../../src/pages/cz/Home/Home";
import Team from "../../../src/pages/cz/Team/Team";
import Gallery from "../../../src/pages/cz/Gallery/Gallery";
import Pricelist from "../../../src/pages/cz/Pricelist/Pricelist";
import Contact from "../../../src/pages/cz/Contact/Contact";

import Employee from "../../../src/pages/cz/Employee/Employee";
import Varga from "../../../src/pages/cz/Varga/Varga";
import Hufnagel from "../../../src/pages/cz/Hufnagel/Hufnagel";
import Morávková from "../../../src/pages/cz/Moravkova/Moravkova";
import Max from "../../../src/pages/cz/Max/max.jsx";
import Lukas from "../../../src/pages/cz/Lukas/lukas";

import Home_en from "../../../src/pages/en/Home/Home";
import Team_en from "../../../src/pages/en/Team/Team";
import Gallery_en from "../../../src/pages/en/Gallery/Gallery";
import Pricelist_en from "../../../src/pages/en/Pricelist/Pricelist";
import Contact_en from "../../../src/pages/en/Contact/Contact";

import Employee_en from "../../../src/pages/en/Employee/Employee";
import Varga_en from "../../../src/pages/en/Varga/Varga";
import Hufnagel_en from "../../../src/pages/en/Hufnagel/Hufnagel";
import Morávková_en from "../../../src/pages/en/Moravkova/Moravkova";
import Max_en from "../../../src/pages/en/Max/max.jsx";
import Lukas_en from "../../../src/pages/en/Lukas/lukas";

function AnimatedRoutes() {
  const location = useLocation();

  const mediaa = window.matchMedia("(max-width: 768px)");

  if (mediaa.matches) {
    $("document").ready(function () {
      $("div").removeClass("animate__animated");
    });
  }

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          {/* CZ */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/cz" element={<Home />} />
          <Route path="/cz/team" element={<Team />} />
          <Route path="/cz/galerie" element={<Gallery />} />
          <Route path="/cz/employee" element={<Employee />} />
          <Route path="/cz/Richard_Varga" element={<Varga />} />
          <Route path="/cz/Ondrej_Hufnagel" element={<Hufnagel />} />
          <Route path="/cz/Leona_Moravkova" element={<Morávková />} />
          <Route path="/cz/Max" element={<Max />} />
          <Route path="/cz/Lukas_Novelinka" element={<Lukas />} />
          <Route path="/cz/pricelist" element={<Pricelist />} />
          <Route path="/cz/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          {/* EN */}
          <Route exact path="/en" element={<Home_en />} />
          <Route path="/en/team" element={<Team_en />} />
          <Route path="/en/gallery" element={<Gallery_en />} />
          <Route path="/en/employee" element={<Employee_en />} />
          <Route path="/en/Richard_Varga" element={<Varga_en />} />
          <Route path="/en/Ondrej_Hufnagel" element={<Hufnagel_en />} />
          <Route path="/en/Leona_Moravkova" element={<Morávková_en />} />
          <Route path="/en/Max" element={<Max_en />} />
          <Route path="/en/Lukas_Novelinka" element={<Lukas_en />} />
          <Route path="/en/pricelist" element={<Pricelist_en />} />
          <Route path="/en/contact" element={<Contact_en />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default AnimatedRoutes;
