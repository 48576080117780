import React, { useState, useEffect } from "react";
import { useWindowScroll } from "react-use";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AnimatedRoutes from "./components/AnimatedRoutes/AnimatedRoutes";
import { Triangle } from "react-loader-spinner";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loading ? (
        <div className="app">
          <img className="loadersvg" src="../../../../images/kingico.svg" draggable="false" />
          <Triangle height="100" width="100" color="#f9b233" ariaLabel="triangle-loading" />
        </div>
      ) : (
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
