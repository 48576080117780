import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import "../../cz/Gallery/Gallery.css";
import Navbar from "../../../components/Navbar/Navbar";

import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { Triangle } from "react-loader-spinner";

export default function Gallery() {
  const images = [1, 2, 3, 4].map((id) => ({
    src: `.././images/gallery${id}.jpg`,
  }));

  const [galleryloading, setGalleryloading] = useState(false);

  useEffect(() => {
    setGalleryloading(true);
    setTimeout(() => {
      setGalleryloading(false);
    }, 1000);
  }, []);

  return (
    <>
      {galleryloading ? (
        <div className="app">
          <img className="loadersvg" src="../../../../images/kingico.svg" draggable="false" />
          <Triangle height="100" width="100" color="#f9b233" ariaLabel="triangle-loading" />
        </div>
      ) : (
        <div className="Gallery-page">
          <Helmet>
            <title>Royal Class Barber Shop | Galerie</title>
            <meta name="description" content="Lorem ipsum dolor sit amet" />
          </Helmet>

          <Navbar />

          <section id="GalleryCard">
            <div className="container-fluid">
              <div className="slideshow">
                <Carousel images={images} style={{ height: 830, width: 2000 }} playIcon={false} pauseIcon={false} leftIcon={<i class="bx bx-chevron-left"></i>} rightIcon={<i class="bx bx-chevron-right"></i>} maxIcon={<i class="bx bx-expand-alt"></i>} minIcon={<i class="bx bx-x"></i>} objectFit={"contain"} />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
