import React from "react";

import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import "./Contact.css";
import Navbar_en from "../../../components/Navbar_en/Navbar_en";

export default function Contact() {
  const getYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
  };

  return (
    <motion.div className="Contact-page" initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}>
      <Helmet>
        <title>Royal Class Barber Shop | Contact</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <Navbar_en />
      <section className="Contact">
        <div className="conatiner">
          <div className="row">
            <div className="col-md-6">
              <div className="cardc animate__animated animate__slideInLeft">
                <div className="card-text">
                  <img className="king-svg" src=".././images/kingico.svg" draggable="false" />
                  <h2>ADDRESS</h2>
                  <p>
                    Royal Class Barber Shop
                    <br />
                    Resselovo náměstí 136
                    <br />
                    Chrudim
                    <br />
                    Czech Republic
                    <br />
                    Next to T-Mobile
                    <br />
                  </p>
                </div>
                <a href="https://www.google.com/maps/place/Royal+Class+Barber+Shop/@49.9518067,15.7921853,17z/data=!3m1!4b1!4m5!3m4!1s0x470dcbce34f90545:0x5f35a4b1b0e9c735!8m2!3d49.9518067!4d15.794374">
                  <div className="navigation">
                    <div className="centernavig">
                      <h3>Navigate</h3>
                      <i class="bx bxs-map-pin"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cardc animate__animated animate__slideInRight">
                <div className="card-text">
                  <i class="bx bx-info-square"></i>
                  <h2>CONTACT</h2>
                  <p>
                    <div className="center">
                      <i class="bx bxs-phone"></i>
                      <a href="tel:+420702800307"> +420702800307</a>
                    </div>
                  </p>
                  <p>
                    <div className="center">
                      <i class="bx bxl-instagram"></i>
                      <a href="https://www.instagram.com/royalclassbarber/?hl=cs"> royalclassbarber</a>
                    </div>
                  </p>
                  <div className="reserve">
                    <p style={{ textAlign: "center" }}>
                      <a className="rezervationc" href="https://royal-class.reservio.com/">
                        BOOK YOURSELF
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="https://www.google.com/maps/dir/49.9059283,15.8169852/Royal+Class+Barber+Shop,+Resselovo+n%C3%A1m.+136,+537+01+Chrudim/@49.9288622,15.7777936,13z/data=!3m1!4b1!4m16!1m6!3m5!1s0x470dcbce34f90545:0x5f35a4b1b0e9c735!2sRoyal+Class+Barber+Shop!8m2!3d49.9518067!4d15.794374!4m8!1m1!4e1!1m5!1m1!1s0x470dcbce34f90545:0x5f35a4b1b0e9c735!2m2!1d15.794374!2d49.9518067">
          <div className="map">
            <img src=".././images/map.png" alt="mapa" className="animate__animated animate__fadeInUp" />
            <div className="footer">Created by Matěj Musílek copyright © {getYear()} Royal Class Barber Shop, Všechna práva vyhrazena.</div>
          </div>
        </a>
      </section>
    </motion.div>
  );
}
