import React from "react";

import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import $ from "jquery";

import Navbar from "../../../components/Navbar/Navbar";
import "./Home.css";

export default function Home() {
  $("document").ready(function () {
    $(".cookie-btn").on("click", function () {
      $(".cookie-container").hide(1);
    });
  });

  document.documentElement.style.setProperty("--animate-duration", "400ms");
  return (
    <motion.div className="main-page" initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}>
      <Helmet>
        <title>Royal Class Barber Shop | Home</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <Navbar />
      <section id="Home">
        <video id="Video" autoPlay="autoplay" muted loop playsInline preload="metadata">
          <source src="./images/promovideo.mp4" type="video/mp4" />
        </video>
        <div className="background animate__animated animate__zoomIn"></div>
        <div className="btn">
          <a href="https://royal-class.reservio.com/" className="btn">
            Rezervovat
          </a>
        </div>
        <div className="socialicons">
          <table className="tableico animate__animated animate__flash animate__delay-2s">
            <tr>
              <td>
                <a href="https://www.instagram.com/royalclassbarber/?hl=cs">
                  <img src="./images/instagram.png" alt="Instagram" draggable="false" />
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className="cookie-container">
          <p>Tento web používá cookies, aby vám zajistil co nejlepší zážitek na našich webových stránkách. Používáním našich stránek souhlasíte s cookies.</p>
          <button className="cookie-btn">Akceptovat</button>
        </div>
      </section>
    </motion.div>
  );
}
