import React from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./Team.css";
import Navbar from "../../../components/Navbar/Navbar";

export default function Team() {
  document.documentElement.style.setProperty("--animate-duration", "900ms");
  return (
    <motion.div className="team-page" initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }} id="trigger">
      <Helmet>
        <title>Royal Class Barber Shop | Team</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <Navbar />
      <section id="Team">
        <div className="team_whole">
          <div className="containerteam">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="cardteam animate__animated animate__fadeInDown">
                    <div className="contentteam">
                      <div className="imgteam">
                        <img draggable="false" src=".././images/hufnagel.png" alt="person" />
                      </div>
                      <div className="contentBteam">
                        <h3>
                          Ondřej Hufnagel
                          <br />
                          <span>Hairdresser</span>
                        </h3>
                      </div>
                    </div>
                    <ul className="sci">
                      <li>
                        <a href="https://www.instagram.com/royalclassbarber/?hl=cs" className="border-none">
                          <i class="bx bxl-instagram"></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="more-info">
                      <li>
                        <Link as={Link} to={"/cz/Ondrej_Hufnagel"}>
                          <i class="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cardteam animate__animated animate__fadeInRight">
                    <div className="contentteam">
                      <div className="imgteam">
                        <img draggable="false" src=".././images/moravkova.png" alt="person" />
                      </div>
                      <div className="contentBteam">
                        <h3>
                          LEONA MORÁVKOVÁ "LEA"
                          <br />
                          <span>Hairdresser</span>
                        </h3>
                      </div>
                    </div>
                    <ul className="sci">
                      <li>
                        <a href="https://www.instagram.com/royalclassbarber/?hl=cs" className="border-none">
                          <i class="bx bxl-instagram"></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="more-info">
                      <li>
                        <Link as={Link} to={"/cz/Leona_Moravkova"}>
                          <i class="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cardteam animate__animated animate__fadeInUp">
                    <div className="contentteam">
                      <div className="imgteam">
                        <img draggable="false" src=".././images/max.jpeg" alt="person" />
                      </div>
                      <div className="contentBteam">
                        <h3>
                          Maxmilián
                          <br />
                          <span>Hairdresser</span>
                        </h3>
                      </div>
                    </div>
                    <ul className="sci">
                      <li>
                        <a href="x" className="border-none">
                          <i class="bx bxl-instagram" style={{ display: "none" }}></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="more-info">
                      <li>
                        <Link as={Link} to={"/cz/Max"}>
                          <i class="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cardteam animate__animated animate__fadeInUp">
                    <div className="contentteam">
                      <div className="imgteam">
                        <img draggable="false" src=".././images/lukas.jpg" alt="person" />
                      </div>
                      <div className="contentBteam">
                        <h3>
                          Lukáš Novelinka
                          <br />
                          <span>Hairdresser</span>
                        </h3>
                      </div>
                    </div>
                    <ul className="sci">
                      <li>
                        <a href="x" className="border-none">
                          <i class="bx bxl-instagram" style={{ display: "none" }}></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="more-info">
                      <li>
                        <Link as={Link} to={"/cz/Lukas_Novelinka"}>
                          <i class="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cardteam animate__animated animate__fadeInUp">
                    <div className="contentteam">
                      <div className="imgteam">
                        <img draggable="false" src=".././images/wantyou.png" alt="person" />
                      </div>
                      <div className="contentBteam">
                        <h3>
                          Hledáme tebe
                          <br />
                          <span>Hairdresser</span>
                        </h3>
                      </div>
                    </div>
                    <ul className="sci">
                      <li>
                        <a href="x" className="border-none">
                          <i class="bx bxl-instagram" style={{ display: "none" }}></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="more-info">
                      <li>
                        <Link as={Link} to={"/cz/contact"}>
                          <i class="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cardteam animate__animated animate__fadeInRight">
                    <div className="contentteam">
                      <div className="imgteam">
                        <img draggable="false" src=".././images/wantyou.png" alt="person" />
                      </div>
                      <div className="contentBteam">
                        <h3>
                          Hledáme tebe
                          <br />
                          <span>Hairdresser</span>
                        </h3>
                      </div>
                    </div>
                    <ul className="sci">
                      <li>
                        <a href="x" className="border-none">
                          <i class="bx bxl-instagram" style={{ display: "none" }}></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="more-info">
                      <li>
                        <Link as={Link} to={"/cz/contact"}>
                          <i class="bx bx-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
