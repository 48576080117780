import React from "react";

import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import "../../../pages/cz/Employee/Employee.css";
import Navbar_en from "../../../components/Navbar_en/Navbar_en";

export default function Employee() {
  return (
    <motion.div className="employee-page" initial={{ width: "-100" }} animate={{ width: "100%" }} exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}>
      <Helmet>
        <title>Royal Class Barber Shop | Lukáš Novelinka</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <Navbar_en />
      <section id="employee">
        <div className="employee-content">
          <div className="container">
            <h1 className="animate__animated animate__fadeIn">Lukáš Novelinka</h1>
            <h3>Hairdresser</h3>
            <div className="picture">
              <div className="imgteam animate__animated animate__pulse">
                <img draggable="false" src=".././images/lukas.jpg" alt="person" />
              </div>
            </div>
            <p className="animate__animated animate__fadeInUp">I'm Lukas, cutting is my job and hobby at the same time. I want to make the customer feel satisfied in all aspects, whether it's a haircut or a pleasant atmosphere. With every cut I try to improve my skills. So if you want a good quality haircut and relax at the same time, don't hesitate to book.</p>
            <div className="soc animate__animated animate__fadeInUp">
              <a href="https://www.instagram.com/royalclassbarber/?hl=cs">
                <i class="bx bxl-instagram"></i>
              </a>
            </div>
            <div className="toTeam">
              <Link as={Link} to={"/en/team"}>
                <i class="bx bx-chevron-left"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}
